import { motion } from 'framer-motion';
import logo from '../img/logo.svg';

function Footer() {
   const thisYear = new Date().getFullYear();
   return (
      <motion.footer
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         
         <img src={logo} className="mb-2" height="90" alt="Logo" />

         <p>&copy; Ambiance {thisYear}. All Rights Reserved.</p>
         <p>Powered by <a href="https://milanesieditore.it" target="_blank" rel="noopener noreferrer">Milanesi Editore</a>.</p>
      </motion.footer>
   );
}

export default Footer;