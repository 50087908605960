import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// gallery images
import gallery1 from './img/gallery/gallery1.jpg';
import gallery2 from './img/gallery/gallery2.jpg';
import gallery3 from './img/gallery/gallery3.jpg';
import gallery4 from './img/gallery/gallery4.jpg';
import gallery5 from './img/gallery/gallery5.jpg';
import gallery6 from './img/gallery/gallery6.jpg';
import gallery7 from './img/gallery/gallery7.jpg';
import gallery8 from './img/gallery/gallery8.jpg';
import gallery9 from './img/gallery/gallery9.jpg';
import gallery10 from './img/gallery/gallery10.jpg';
import gallery11 from './img/gallery/gallery11.jpg';
import gallery12 from './img/gallery/gallery12.jpg';

export const menuList = [
   {
      name: 'Appetizers',
      items: [
         {
            name: 'Cured meat',
            formula: 'cooked ham, raw ham, suppressed, bacon',
            price: 4.5
         },
         {
            name: 'Cold cuts and cheeses',
            formula: 'cooked ham, raw ham, suppressed, Asiago cheese, seasonal cheeses',
            price: 7
         },
         {
            name: 'Seasonal cheeses',
            formula: 'seasonal cheeses with nuts and fruit',
            price: 7
         },
         {
            name: 'Vegetarians',
            formula: 'seasonal vegetables in oil, spinach, onions, olives',
            price: 3.5
         },
         {
            name: 'Toast',
            formula: 'bread, baked ham and cheeses',
            price: 5.5
         },
         {
            name: 'Club sandwich',
            formula: 'bread, bacon, eggs, cherry tomatoes, iceberg salad, chicken, smoked scamorza cheese, mustard',
            price: 8
         }
      ]
   },
   {
      name: 'Dishes',
      subcategories: [
         {
            name: 'First dishes',
            items: [
               {
                  name: 'Pasta',
                  formula: 'pasta with with seasoning to taste',
                  price: 5
               },
               {
                  name: 'Cream of pumpkin',
                  formula: 'leeks, pumpkin and fresh cream',
                  price: 6.5
               },
               {
                  name: 'Spaghetti Carbonara',
                  formula: 'spaghetti, bacon, egg yolks and pecorino romano',
                  price: 7
               },
               {
                  name: 'Saffron risotto',
                  formula: 'saffron in pistils, rice, onions and Grana Padano',
                  price: 7.5
               }
            ]
         },
         {
            name: 'Main dishes',
            items: [
               {
                  name: 'Cost to blood',
                  formula: '1/2 kg of rare meat with rocket and parmesan cheese flakes',
                  price: 5.5
               },
               {
                  name: 'Mixed grill',
                  formula: 'sliced, ribs, sausages, chicken',
                  price: 5.5
               },
               {
                  name: 'Mixed frying',
                  formula: 'shrimp, cuttlefish, squid, mullet, anchovies',
                  price: 5.5
               },
               {
                  name: 'Warm octopus and potato salad',
                  formula: 'octopus, potatoes, bay leaf and parsley',
                  price: 5.5
               }
            ]
         },
         {
            name: 'Side dishes',
            items: [
               {
                  name: 'French fries',
                  price: 2.5
               },
               {
                  name: 'Potato chips',
                  price: 3
               },
               {
                  name: 'Mixed salad',
                  price: 3
               },
               {
                  name: 'Cooked vegetables',
                  price: 4
               }
            ]
         }
      ]
   },
   {
      name: 'Drinks',
      subcategories: [
         {
            name: 'Alcohol free',
            items: [
               {
                  name: 'Still or sparkling water',
                  formula: '1/2 liter',
                  price: 1
               },
               {
                  name: 'Coca Cola',
                  formula: '1/2 liter',
                  price: 2.5
               },
               {
                  name: 'Peach or lemon tea',
                  price: 3
               },
               {
                  name: 'Coffee',
                  price: 1.5
               },
            ]
         },
         {
            name: 'Beer',
            items: [
               {
                  name: 'Unfiltered blonde',
                  formula: 'craft beer - vol 4.5 % -1/4 liter',
                  price: 3.5
               },
               {
                  name: 'Pale ale',
                  formula: 'craft beer with strong taste and fruit aromas - vol 7.5 % -1/4 liter',
                  price: 4
               },
               {
                  name: 'Strong IPA',
                  formula: 'amber, bitter with fruity aromas - vol 5.5 % -1/2 liter',
                  price: 5.5
               }
            ]
         },
         {
            name: 'Cocktails',
            items: [
               {
                  name: 'Negroni',
                  formula: 'campari, red vermouth and gin',
                  price: 5.5
               },
               {
                  name: 'Malibù Cola',
                  formula: 'malibù, coca cola and cedar juice',
                  price: 6.5
               },
               {
                  name: 'Gin Lemon',
                  formula: 'gin, lemon, sweet and sour',
                  price: 7.5
               },
               {
                  name: 'Mojito',
                  formula: 'cuban rum, fresh mint, lemon, sugar and soda',
                  price: 8
               },
               {
                  name: 'Sex on the beach',
                  formula: 'peach liqueur, vodka, orange and cranberry',
                  price: 9
               }
            ]
         }
      ]
   },
   {
      name: 'Wines',
      subcategories: [
         {
            name: 'Red',
            items: [
               {
                  name: 'Cabernet D.O.C.',
                  formula: 'vol. 13.5 % - from Italy',
                  price: 2.5
               },
               {
                  name: 'Merlot 14 Hands',
                  formula: 'vol. 13.5 % - from United States',
                  price: 2.5
               },
               {
                  name: 'Malbec Dona Paula',
                  formula: 'vol. 14.5 % - from Argentina',
                  price: 3
               }
            ]
         },
         {
            name: 'White',
            items: [
               {
                  name: 'White Pinot',
                  formula: 'vol. 12.5 % - from Italy',
                  price: 2.5
               },
               {
                  name: 'Prosecco',
                  formula: 'vol. 11.5 % - from Italy',
                  price: 3.5
               }
            ]
         }
      ]
   }
];


export const servicesList = [
   {
      icon: <FontAwesomeIcon icon={solid('wifi')} />,
      subtitle: 'Free',
      title: 'Wi-fi',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      icon: <FontAwesomeIcon icon={solid('book-open')} />,
      subtitle: 'Angle of silence',
      title: 'Read book',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      icon: <FontAwesomeIcon icon={solid('child')} />,
      subtitle: 'Play area',
      title: 'Fun for children',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      icon: <FontAwesomeIcon icon={solid('check')} />,
      subtitle: 'Where you want',
      title: 'Take away',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   }
];


export const galleryImgs = [
   {
      src: gallery1,
      alt: 'Meat'
   },
   {
      src: gallery2,
      alt: 'Coca cola with ice'
   },
   {
      src: gallery3,
      alt: 'Roast Patatoes'
   },
   {
      src: gallery4,
      alt: 'Chef dish'
   },
   {
      src: gallery5,
      alt: 'Tea with lemon'
   },
   {
      src: gallery6,
      alt: 'Alcol bottles'
   },
   {
      src: gallery7,
      alt: 'Red Meat'
   },
   {
      src: gallery8,
      alt: 'Lemonate'
   },
   {
      src: gallery11,
      alt: 'Fish dish'
   },
   {
      src: gallery10,
      alt: 'Whisky'
   },
   {
      src: gallery12,
      alt: 'Cin cin'
   },
   {
      src: gallery9,
      alt: 'Wine'
   }
];