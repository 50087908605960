import './Tabs.css';

function Tabs({ btns, activeCat, handleSetActive, children }) {
   return (
      <>
         <div className="tab-list">
            {btns.map((btn, idx) =>
               <button
                  key={idx}
                  id={idx}
                  className={activeCat === btn ? "active" : null}
                  onClick={handleSetActive}
               >
                  {btn}
               </button>
            )}
         </div>
         
         <div className="tab-content">
            {children}
         </div>
      </>
   );
}

export default Tabs;