import './Contact.css';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

function Contatti() {
   return (
      <>
			<HeaderPage
				pageName={'Contact us'}
				subtitle={'leave a message'}
				icon={<FontAwesomeIcon icon={solid('phone')} className="badge-icon" />}
			/>

			<motion.main
            className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section>
					<h2 className="txt-center">Contact information</h2>
					<h3 className="subtitle txt-center mb-2">Where we are</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>

               <ul className="contact-info mb-2">
                  <li>
                     <FontAwesomeIcon icon={solid('location-dot')} />
                     <a href="https://www.google.it/maps/" target="_blank" rel="noopener noreferrer">
                        <address>Your Address 1, Zip - City</address>
                     </a>
                  </li>
                  <li>
                     <FontAwesomeIcon icon={solid('phone')} />
                     <a href="tel:">+39 321 123456</a>
                  </li>             
                  <li>
                     <FontAwesomeIcon icon={solid('envelope')} />
                     <a href="mailto:">youremail@address.com</a>
                  </li>
                  <li>
                     <FontAwesomeIcon icon={brands('facebook-f')} />
                     <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">Connect with us on Facebook</a>
                  </li>
                  <li>
                     <FontAwesomeIcon icon={brands('instagram')} />
                     <a href="https://instagram.com/" target="_blank" rel="noopener noreferrer">Connect with us on Instagram</a>
                  </li>
               </ul>

               <iframe className="map rounded" title="Maps" src="https://www.google.com/maps?q=Milanesi+Editore&t=&z=12&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
				</section>

            <section className="txt-center">
					<h2>Opening hours</h2>
					<h3 className="subtitle mb-2">Come visit</h3>
               <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

               <div className="hours-time">
                  <time>
                     <p className="subtitle">Monday</p>
                     <h3>7:30 - 20:30</h3>
                  </time>
                  <time>
                     <p className="subtitle">Tuesday</p>
                     <h3>Closed</h3>
                  </time>
                  <time>
                     <p className="subtitle">Wednesday</p>
                     <h3>7:30 - 22:00</h3>
                  </time>
                  <time>
                     <p className="subtitle">Thursday</p>
                     <h3>7:30 - 22:00</h3>
                  </time>
                  <time>
                     <p className="subtitle">Friday</p>
                     <h3>6:00 - 0:00</h3>
                  </time>
                  <time>
                     <p className="subtitle">Saturday</p>
                     <h3>6:00 - 1:00</h3>
                  </time>
                  <time>
                     <p className="subtitle">Sunday</p>
                     <h3>10:00 - 22:00</h3>
                  </time>
               </div>
				</section>

            <section className="txt-center">
					<h2>Get in Touch</h2>
					<h3 className="subtitle mb-2">We will get back to you!</h3>
					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br />sed do eiusmod tempor incididunt ut labore et dolore.</p>

               <ContactForm />
				</section>
			</motion.main>

			<Footer />
      </>
   );
}

export default Contatti;