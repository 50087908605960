import { motion } from 'framer-motion';
import { servicesList } from '../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Footer from '../components/Footer';

function Services() {
	return (
		<>
			<HeaderPage
				pageName={'Our Services'}
				subtitle={'different from others'}
				icon={<FontAwesomeIcon icon={solid('handshake')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>We love relaxation</h2>
					<h3 className="subtitle mb-2">Feel at home</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>

               <div className="grid mb-2">
                  {servicesList.map((service, idx) =>
                     <article key={idx}>
                        <i className="badge-icon mb-2">
									{service.icon}
								</i>
                        <h3 className="subtitle">{service.subtitle}</h3>
                        <h2>{service.title}</h2>
                        <p>{service.text}</p>
                     </article>
                  )}
               </div>
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default Services;