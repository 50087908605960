import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import AboutUs from './pages/AboutUs';
import Menus from './pages/Menus';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
	const location = useLocation();
	
	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route path='/' element={<HomeNav />} />
				<Route path='about-us' element={<AboutUs />} />
				<Route path='menus' element={<Menus />} />
				<Route path='services' element={<Services />} />
				<Route path='gallery' element={<Gallery />} />
				<Route path='contact' element={<Contact />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
		</AnimatePresence>
	);
}

export default App;