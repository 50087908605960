import './Menus.css';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { menuList } from '../data.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Tabs from '../components/Tabs';
import Footer from '../components/Footer';

const MenuItems = ({ items }) => {
   return (
      items.map((item, idx) =>
         <li key={idx} className={item.img ? 'photo' : null}>
            {item.img &&
               <img src={item.img} alt={item.name} />
            }
            <h3>
               <strong>{item.name}</strong>
               <span className="badge">$ {item.price}</span>
            </h3>
            {item.formula &&
               <p className="formula">{item.formula}</p>
            }
         </li>
      )
   );
}

function Menu() {
   const btns = menuList.map(obj => obj.name);

   const [activeCat, setActiveCat] = useState(btns[0]),
         handleSetActive = e => setActiveCat(btns[e.target.id]),
         [menuSubcat] = menuList.filter(menu => menu.name === activeCat);

   return (
      <>
         <HeaderPage
				pageName={'Our Menus'}
				subtitle={'delicious choices'}
				icon={<FontAwesomeIcon icon={solid('utensils')} className="badge-icon" />}
			/>

			<motion.main
            className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section>

               <Tabs
                  btns={btns}
                  activeCat={activeCat}
                  handleSetActive={handleSetActive}
               >
                  <motion.ul
                     className="menu-list"
                     
                     key={activeCat}
                     initial={{ opacity: 0, x: '100%' }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ duration: .8, ease: 'easeOut' }}
                     exit={{ opacity: 0, x: '100%' }}
                  >

                     {menuSubcat.hasOwnProperty('subcategories') ?
                        menuSubcat.subcategories.map((subcat, idx) =>
                           <React.Fragment key={idx}>
                              <li className="subcategory">
                                 <h2 className="badge">{subcat.name}</h2>
                              </li>

                              <MenuItems items={subcat.items} />
                           </React.Fragment>
                        )
                        :
                        <MenuItems items={menuSubcat.items} />
                     }
                     
                  </motion.ul>
               </Tabs>

				</section>
			</motion.main>

			<Footer />
      </>
   );
}

export default Menu;